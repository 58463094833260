<template>
<div class="users-select-component w-100">
    <b-button variant="primary" class="w-100" @click="Show()">{{label? label: "Välj användare"}}</b-button>
    <b-modal size="lg" :title="title" :visible="visible" @hide="Hide()" @ok="Ok()" cancel-title="Avbryt" footer-class="justify-content-center align-items-center" ok-title="Visa" no-close-on-esc no-close-on-backdrop>
        <div class="mt-2 pb-2 mb-2 d-flex justify-content-center" v-if="!IsMultiple">
            <b-button variant="secondary " class="mx-1" @click="Clear()">Rensa</b-button>
            <b-button variant="primary " class="mx-1" @click="Me()">Mig</b-button>
            <b-button variant="info" class="mx-1" @click="All()">Alla</b-button>
            <b-button variant="success" class="mx-1" @click="Paying(true)">Betalande</b-button>
            <b-button variant="danger" class="mx-1" @click="Paying(false)">Ej Betalande</b-button>
        </div>
        <div class="d-flex flex-wrap justify-content-between">
            <div v-for="user in users" :class="{'selected': UserSelected(user)}" :key="user.username" @click.stop.prevent="ToogleUser($event, user)" class="user-select-item">
                <b-img class="user-select-image" center rounded="circle" :src="useravatar(user)"></b-img>
                {{user.username}}
            </div>
        </div>
    </b-modal>
</div>
</template>

<script>
/* eslint-disable */
export default {
    props: {
        id: {
            type: String
        },
        label: {
            type: String
        },
        title: {
            type: String,
            required: true
        },
        selected: {
            type: Array
        },
        users: {
            type: Array
        },
        all: {
            type: Boolean
        },
        multiple: {
            type: Boolean
        }
    },
    data() {
        return {
            users_selected: {},
            visible: false
        };
    },
    computed: {
        CurrentUser() {
            return this.$store.state.auth.user;
        },
        IsMultiple() {
            return this.multiple;
        }
    },
    methods: {
        ToogleUser: function (event, user) {
            if (this.UserSelected(user)) {
                //remove
                delete this.users_selected[user.id];
                event.currentTarget.className = "user-select-item";
            } else {
                // add
                this.users_selected[user.id] = user;
                event.currentTarget.className = "user-select-item selected";
            }
        },
        UserSelected(user) {
            if (this.users_selected && this.users_selected[user.id])
                return true;
            return false;
        },
        current_selected() {
            var data = [];
            for (var id in this.users_selected) {
                data.push(this.users_selected[id])
            }

            return data.sort(function (a, b) {
                if (a.username < b.username) //sort string ascending
                    return -1
                if (a.username > b.username)
                    return 1
                return 0 //default return value (no sorting)
            })
        },
        Ok() {
            this.emit();
            this.Hide();
        },
        Show() {
            this.visible = true;
        },
        Hide() {
            this.visible = false;
        },
        useravatar(user) {
            return this.$images.userAvatar(user.id, user.imgversion);
        },
        setUserDefault() {
            this.users_selected = {};
            if (this.all) {         
                this._paying(true);
            }                 
            else {
                this._me(this.id);
            }
            this.emit();
        },
        _me(id) {
            this.users_selected = {};
            if(!id)
                id = this.CurrentUser.id;
            for (var i = 0; i < this.users.length; i++) {
                if (this.users[i].id == id) {
                    this.users_selected[id] = this.users[i];
                    break;
                }
            }
        },
        Me() {
            this._me();
            this.emit();
            this.Ok();
        },
        Clear() {
            this.setUserDefault();
            this.Ok();
        },
        All() {
            this.users_selected = {};
            for (var i = 0; i < this.users.length; i++) {
                var u = this.users[i];
                this.users_selected[u.id] = u;
            }
            this.Ok();
        },
        _paying(paid) {
            this.users_selected = {};
            for (var i = 0; i < this.users.length; i++) {
                var u = this.users[i];
                if (paid && u.access > 10) {
                    this.users_selected[u.id] = u;
                } else if (!paid && u.access <= 10) {
                    this.users_selected[u.id] = u;
                }
            }
        },
        Paying(paid) {
            this._paying(paid);
            this.Ok();
        },
        emit() {
            this.$emit('input', this.current_selected());
        }
    },
    watch: {
        all() {
            this.setUserDefault();
        }
    },
    mounted() {
        this.setUserDefault();
    }
}
</script>
